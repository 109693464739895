import { log, api } from ":mods";
import { ENDPOINTS } from "../const";
import { Login, TokenObject } from "../models";
import { _token_actions } from "../store";

export async function login(data: Login) {
  // store.alert.send({ type: "success", message: "test", no_timer: true });
  // return Promise.reject();
  return api
    .postAuth<TokenObject>(ENDPOINTS.post_token, data)
    .then(async (res) => {
      // console.log("login res :: ", res);
      // document.cookie = res.headers["set-cookie"].join("; ");
      _token_actions.setJWT(res.data);
      return Promise.resolve();
    })
    .catch((err) => {
      // const can_bypass_err = api.canBypassErr(err);
      // if (can_bypass_err) {
      // 	if (can_bypass_err.err_msg) {
      // 		// store.alert.send({
      // 		// 	type: "error",https://www.youtube.com/watch?v=FK9PbmAMVeA
      // 		// 	message: can_bypass_err.err_msg,
      // 		// });
      // 	} else {
      // 		// store.alert.send({
      // 		// 	type: "warning",
      // 		// 	message: can_bypass_err.msg,
      // 		// });
      // 	}
      // 	log.dev.info("can_bypass_err :: ", can_bypass_err);
      // 	// return Promise.resolve(can_bypass_err);
      // }
      log.dev.info("login error :: ", err);
      // store.alert.send({
      // 	type: "error",
      // 	message: service.api.decode_error(err, "error logging in"),
      // });
      return Promise.reject(err);
    });
}
